const SecondEntry = () => {
    return (<div>
    <h1>Quill's Journal</h1>
    <h3>Entry - October 26th 2019</h3>
    <p>
        I think it's over. The fifth one was offered at the power lines. With this, the Ten-Thousand-Day Promise should hold...<br/>
        I fear, however, that this one may bring unforeseen problems. They were children, certainly, but this last one...<br/>
        The Beast's arrival last year has put the court on edge... perhaps it's time I make my move. His name shouldn't be pronounced...
    </p>
    <br/>
    <h3>Entry - November 13th 2019</h3>
    <p>
        My knowledge of the customs and of the Bulwark itself seems to have attracted the favor of Autumn. Benjamin doesn't seem to have <br/>
        noticed my actions towards the Promise, and it's best it remained that way... he does refuse to speak to me most of the time however...<br/>
        I truly hope my writing is still good enough. I disappointed Him once before.
    </p>
    <p>9 entries remaining.</p>

    </div>
    
    );
  };
  
  export default SecondEntry;