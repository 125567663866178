const SecondEntryLark = () => {
    return (<div>
    <p>
    < iframe width="25" height="25" src="https://www.youtube.com/embed/neBNSWqErdk?rel=0&amp;showinfo=0&autoplay=1&player.setVolume(4)" allow="autoplay" frameborder="0" allowfullscreen></iframe>
        <h3>(OOC : Players, please click the play button above as you enter this page once, then don't click again. It is somewhat loud.)</h3>
        <li>
            <ul>Hmm... So Clemmie really thinks it's gonna happen again?</ul>
            <ul>Yeeeeah, but hey, it's not so bad, we fixed it--- okay okay Lucian fixed it, but hey we helped!</ul>
            <ul>Y-yeah but Kingsley I dunno if I even want to have such a big part this time around.</ul>
            <ul>Cole, get a hold of yourself, sheesh. This is our act, it's what we do!</ul>
            <ul>You're not going to.. leave too, right?</ul>
            <ul>What no of course I won't you idiot. Someone needs to watch you 'round Clemmie anyway.</ul>
            <ul>W-what do you mean by that?!</ul>
            <ul>You know what I mean...</ul>
            <ul>I-it's not like that!</ul>
            <ul>It's totally like that.</ul>
            <ul>...okay yes it IS like that but shut up. Sh-shut up!</ul>
            <ul>Look, Milio was a lot of fun, but he didn't leave cuz of you, moron. He had other aspirations!</ul>
            <ul>I-I just don't want you to----</ul>
            <ul>I. Won't. Leave. Who else is gonna feed you crickets while you sleep anyway?</ul>
            <ul>W-what?</ul>
            <ul>Need to get more from that vendor, now that I think about it...</ul>
            <ul>W-what vendor??</ul>
            <ul>You know, the one that sold you the mark?</ul>
            <ul>Oh yeah... W-wait you're feeding me crickets?!</ul>    
        </li>
    </p>
    <p>4 entries remaining.</p>
    </div>
    );
  };
  
  export default SecondEntryLark;