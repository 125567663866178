const TruthAndTreacheryFirstEntry = () => {
    return (<div>
    <p>
        <li>
        <ul>The winning team is the one to reach 3 points first.</ul>
        <ul>The identity of players and teams shall remain a secret.</ul>
        <ul>Pawns shall be selected at the player's discretion.</ul>
        <ul>The losing team shall lose their staked title and pawns.</ul>
        <ul>The winning team shall dictate which events remain in continuity.</ul>
        <ul>The game cannot violate oaths.</ul>
        <ul>The game cannot violate pledges.</ul>
        <ul>Each playing pawn shall receive _____________________________________________________________</ul>
        <ul>The specifics on other game rules are to be determined by the players, and are subject to oath.</ul>
        <ul>No rule not dictated here, or outside the determined rules, is binding.</ul>
        <ul>For any questions regarding game specifics, please address Thorne.</ul>
        </li>

    </p>
    <p>2 entries remaining.</p>
    </div>
    );
  };
  
  export default TruthAndTreacheryFirstEntry;