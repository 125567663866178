import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Switch, Route } from "react-router-dom";
import FirstEntry from "./pages/FirstEntry";
import SecondEntry from "./pages/SecondEntry";
import ThirdEntry from "./pages/ThirdEntry";
import LavenderSideEntry from "./pages/LavenderSideEntry";
import FirstEntryLark from "./pages/FirstEntryLark";
import LastEntry from "./pages/LastEntry";
import SecondEntryLark from "./pages/SecondEntryLark";
import GOTVFirstEntry from "./pages/GOTVFirstEntry";
import TruthAndTreacheryFirstEntry from "./pages/TruthAndTreacheryFirstEntry";





import Home from "./pages/Home";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="/maelstrom" element={<FirstEntry />} />
          <Route path="/maelstrom/benjamin" element={<SecondEntry />} />
          <Route path="/maelstrom/storyteller" element={<ThirdEntry />} />
          <Route path="/lavender" element={<LavenderSideEntry />} />
          <Route path="/absurdity" element={<FirstEntryLark />} />
          <Route path="/absurdity/hartebeest" element={<SecondEntryLark />} />
          <Route path="/watchtower" element={<GOTVFirstEntry />} />
          <Route path="/thorns" element={<TruthAndTreacheryFirstEntry/>} />

      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);